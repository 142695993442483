<template>
  <div class="profileMain container">
    <img src="../assets/img/sticker-2.jpeg" />
    <vue-qr
      :text="barcodeId"
      :size="200"
    ></vue-qr>
    
  </div>
</template>

<script>
import VueQr from "vue-qr";

export default {
  components: {
    VueQr,
  },
  data() {
    return {
      barcodeId :"",
      src:"../assets/img/logo.png"
    };
  },
  created() {
     if (this.$store.getters.getAuth == false) {
      this.$router.push("/login");
    }
    var storeData = this.$store.getters.getData;
    console.log(storeData);
    this.barcodeId = storeData.barcodeId
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
.profileMain {
  height: 100%;
  min-height: calc(100vh - 250px);
  margin: 3vh auto;
  box-shadow: inset 12px 12px 51px #00000029, 20px 20px 20px #00000029;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;

  > img {
    height: 50vh;
    max-height: 250px;
    min-height: 100px;
    max-width: 95%;
    object-fit: contain;
    margin: 20px 0;
  }
}
</style>
